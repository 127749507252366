.gallery {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	position: relative;
	padding: 50px;
}

.photo {
	margin: 5px;
	cursor: pointer;
	overflow: hidden;
}

.photo img {
    width: 100%;
    transition: transform 500ms ease;
    width: 18vw !important;
	height: 18vw !important;

	border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;
}

.photo img:hover {
	transform: scale(1.1);
}

.photoAddCover {
	margin: 5px;
	cursor: pointer;
	overflow: hidden;
	width: 18vw !important;
	height: 18vw !important;
}

.btnAddPhoto {
	width: 100%;
	transition: transform 500ms ease;
	width: 15vw !important;
	height: 15vw !important;
	border-radius: 20px 20px 20px 20px;
	-moz-border-radius: 20px 20px 20px 20px;
	-webkit-border-radius: 20px 20px 20px 20px;
	border: dashed;
	color: #4d1a05 !important;
	border-color: #4d1a05 !important;
	margin-top: 1.2em;
	margin-left: 1.2em;
}

	.btnAddPhoto:hover {
		transform: scale(1.1);
		color: #4d1a05 !important;
		border-color: #4d1a05 !important;
		-webkit-box-shadow: 0px 0px 10px -2px rgba(77,26,5,1);
		-moz-box-shadow: 0px 0px 10px -2px rgba(77,26,5,1);
		box-shadow: 0px 0px 10px -2px rgba(77,26,5,1);
	}

.iconAddImage {
	font-size: 42px;
	color: #4d1a05;
}

.btnDeleteImg {
	float: right;
	z-index: 1;
	color: #4d1a05 !important;
	border-color: #4d1a05 !important;
	margin-top: 2px;
	margin-right: 2px;
	
}

	.btnDeleteImg:hover {
		transform: scale(1.1);
		color: white !important;
		border-color: #4d1a05 !important;
		background-color: #4d1a05;
	}

.ant-modal-header {
	background-color: #4d1a05 !important;
}

.ant-modal-close {
	color: white !important;
}

.ant-modal-title {
	color: white !important;
}

.btnModalCancel:hover {
	color: #4d1a05 !important;
	border-color: #4d1a05 !important;
}

.btnModalUpd:hover {
	color: white !important;
	border-color: #4d1a05 !important;
	background-color: #4d1a05 !important;
}

.btnModalUpd:disabled,
.btnModalUpd[disabled] {
	border: 1px solid #999999 !important;
	background-color: #cccccc !important;
	color: #666666 !important;
}

.pInput{
	text-align:center;
}

@media (max-width: 991.98px) {
	.photo img {
		width: 32vw !important;
		height: 32vw !important;
	}
	.photoAddCover {
		width: 32vw !important;
		height: 32vw !important;
	}
	.btnAddPhoto {
		width: 29vw !important;
		height: 29vw !important;
	}
}

@media (max-width: 700.98px) { 
    .photo img {
		width: 60vw !important;
		height: 60vw !important;
	}
	.photoAddCover {
		width: 60vw !important;
		height: 60vw !important;
	}

	.btnAddPhoto {
		width: 57vw !important;
		height: 57vw !important;
	}
}
