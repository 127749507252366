.app-upload > .ant-upload.ant-upload-select-text > .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.app-upload > .ant-upload {
    width: 128px;
    height: 128px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color .3s;
}
.app-upload img {
    margin: 0;
    width: 100%;
}