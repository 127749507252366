.checkout-container {
    background-color: white;
    width: 450px;
    margin: auto;
    padding: 10px;
}

.checkout-container img {
    width: 100%;
}

.checkout-container h2 {
    text-align: center;
}

.event-description {
    text-align: center;
    padding: 0 20px;
}

.event-state-title {
    color: red;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    border: red solid;
    transform: rotate(-3deg);
    margin-top: 30px;
}

.code-container {
    border: black 2px dashed;
    font-weight: bold;
    text-align: center;
    padding: 20px;
}