.divContainer {
    padding: 50px  0 !important;
}

.textHeader {
    font-size: 30px;
}

.photoH {
    width: 100%;
    height: auto;
}

.divText {
    text-align: center;
    font-size: 16px;
    background-color: white;
    padding-top: 45px !important;
    padding-left: 45px !important;
    padding-right: 45px !important;
    padding-bottom: 45px !important;
    margin-top: 50px !important;
    margin-bottom: 100px !important;
    -webkit-box-shadow: 0px 0px 20px -2px rgba(128,128,128,1);
    -moz-box-shadow: 0px 0px 20px -2px rgba(128,128,128,1);
    box-shadow: 0px 0px 20px -2px rgba(128,128,128,1);
}

.textR {
    margin-left: -45px !important;
    margin-right: 45px !important;
}

.textL {
    z-index: 2;
    margin-left: 45px !important;
    margin-right: -45px !important;
}

.divImg {
    height: 40vw;
}

.imageL {
    z-index: 1;
    border-radius: 20px 0px 0px 0px;
    -moz-border-radius: 20px 0px 0px 0px;
    -webkit-border-radius: 20px 0px 0px 0px;
}

.imageR {
    border-radius: 0px 0px 20px 0px;
    -moz-border-radius: 0px 0px 20px 0px;
    -webkit-border-radius: 0px 0px 20px 0px;
}

@media (max-width: 768.98px) { 
    .textR {
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 120px !important;
    }

    .photoH {
        width: 100%;
        height: auto;
    }
}