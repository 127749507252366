.container{
    padding-top: 100px;
}

.btnSubmit{
    background-color: #4d1a05 !important;
    border-color: #4d1a05 !important;
    color: white !important;
}

.btnSubmit:hover{
    color: #4d1a05 !important;
    background-color: white !important;
    border-color:  #4d1a05 !important;
}

@media (max-width: 575.98px) { 
    .divContent {
        margin-left: 0px !important;
    }
}