iframe {
    width: 100%;
    height: 360px;
    border: 0;
}

.ant-modal-body img {
    width: 100%;
    height: auto;
    margin-top: 24px;
}

.ant-modal-body h2 {
    text-align: center;
}

.ant-tabs-tab:hover {
    color: #4d1a05 !important;
}

.ant-tabs-tab-btn:active{
    color: #4d1a05 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
    font-weight: 500;
}

.ant-tabs-tab-active{
    background-color: #4d1a05 !important;
}