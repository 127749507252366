.drawer-menu-item {
    margin: 0 -25px;
    text-transform: uppercase;
}

.drawer-menu-item a {
    color: inherit;
    font-weight: bold;
}

.drawer-menu-item a.active {
    border-left: #4d1a05 solid 5px;
    padding-left: 5px;
}

.layout-menu {
    height: 80px;
    background-color: white;
    box-shadow: 0px 10px 10px #00000080;
    position: relative;
    z-index: 2;
}

.layout-menu li {
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-size: larger;
    text-transform: uppercase;
    width: 130px;
    text-align: center;
    padding: 27px 20px;
}

.layout-menu li a:hover, .layout-menu li a.active {
    color: #4d1a05;
    border-bottom: #4d1a05 solid 2px;
}

.layout-menu li a {
    color: inherit;
}

.layout-menu ul {
    padding: 0;
    margin: 0;
    text-align: center;
    height: 100%;
}

.menu-icon {
    font-size: 35px;
    margin-top: 22px;
    margin-left: 22px;
}

@media (min-width: 1176px) { 
    .menu-icon {
        display: none;
    }
}

@media (max-width: 1176px) { 
    .layout-menu ul {
        display: none;
    }
}
