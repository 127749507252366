@keyframes bounce {
    0%, 20%, 60%, 100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  
    40% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  
    80% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
}

.man-btns {
    display: flex;
    justify-content: center;
}

.man-link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #4d1a05;
    padding: 5px;
    height: 90px;
    width: 110px;
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 10px 5px #bfbfbf;
    cursor: pointer;
}

.man-link-button:hover {
    color: #4d1a05;
    animation: bounce 1s;
}

.man-link-button > p {
    font-weight: bold;
    margin: 5px 0 0 0;
}

.man-link-button .anticon svg {
    height: 2.5em;
    width: 2.5em;
}