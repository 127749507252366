.loginContainer{
    margin-top: 200px;
    position: absolute;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.login-banner{
    height: 30px;
    background-color: black;
}

.login-logo{
    width: 200px;
    display: block;
    position: absolute;
    left: 50%;
    z-index: 3;
    margin-left: -100px;
    margin-top: -110px;
}

.radiusContent{
    border-radius: 0px 0px 20px 20px;
    -moz-border-radius: 0px 0px 20px 20px;
    -webkit-border-radius: 0px 0px 20px 20px;
}

.radiusBanner{
    border-radius: 20px 20px 0px 0px;
    -moz-border-radius: 20px 20px 0px 0px;
    -webkit-border-radius: 20px 20px 0px 0px;
}