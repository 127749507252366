.layout-content {
    background-color: white;
}

.layout-footer {
    background-color: black;
    color: white;
    padding: 20px;
    text-align: center;
}

.layout-footer a {
    color: inherit;
}

.layout-footer .social-icon {
    font-size: 30px;
    margin: 5px;
}

.layout-logo {
    width: 200px;
    display: block;
    position: absolute;
    left: 50%;
    z-index: 3;
    margin-left: -100px;
    margin-top: -60px;
}

.layout-top-banner { 
    height: 50px;
    background-color: black;
}

.login-icon {
    font-size: 25px;
    padding: 2px;
}

.link-icon {
    float: right;
    background-color: black;
    width: 30px;
    height: 30px;
    margin-right: -50px;
    margin-top: -21px;
    cursor:pointer;
}

@media (max-width: 991.98px) {
    .layout-logo {
        width: 170px;
        display: block;
        position: absolute;
        left: 50%;
        z-index: 3;
        margin-left: -75px;
        margin-top: -50px;
    }
}

@media (max-width: 767px) {
    .link-icon {
        margin-right: -20px;
        background-color: white;
    }
    .login-icon {
        color:black;
    }
}